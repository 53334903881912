<template>
	<div>
		<transition
			name="auth-form-fade"
			mode="out-in"
		>
			<KeepAlive>
				<component
					:is="currentStep"
					:phoneNumber="phoneNumber"
					@fillPhoneNumber="fillPhoneNumber"
				/>
			</KeepAlive>
		</transition>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "AuthForm",
	components: {
		Step1: () => import('./steps/FirstStep.vue'),
		Step2: () => import('./steps/SecondStep.vue'),
		Step3: () => import('./steps/ThirdStep.vue'),
		Step4: () => import('./steps/FourthStep.vue'),
	},
	data()
	{
		return {phoneNumber: ""};
	},
	computed: {
		...mapGetters({
			step: 'getAuthStep',
			authPhone: "getAuthPhone",
		}),
		currentStep()
		{
			return `Step${this.step}`;
		}
	},
	methods: {
		fillPhoneNumber(phone)
		{
			this.phoneNumber = phone;
		}
	},
	mounted()
	{
		if (this.authPhone) this.fillPhoneNumber(this.authPhone);

		if (window.innerWidth < 767)
		{
			const body = document.querySelector("body");
			body.classList.add("page-no-scroll");
		}

		!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(false);
	},
	beforeDestroy()
	{
		const body = document.querySelector("body");
		body.classList.remove("page-no-scroll");

		!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(true);
	},
}
</script>
<style lang="scss">
.auth-form-fade-enter-active, .auth-form-fade-leave-active
{transition: .3s;}

.auth-form-fade-enter, .auth-form-fade-leave-to
{opacity: 0;}

.auth-form__title
{
	color: $textColor;
	font-family: $mainFontBoldNew;
	font-weight: 700;
	font-size: 24px;
	line-height: 133%;
	text-align: center;
}

.auth-form__subtitle
{
	color: $textColor;
	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
}

.auth-step__form
{
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.step-arrow__icon
{
	path
	{fill: $greyTextLight;}
}

@media (max-width: 767px)
{
	.auth-form__title
	{
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		text-align: left;
	}

	.auth-form__subtitle
	{margin-top: 20px;}
}
</style>